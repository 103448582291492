import React, { useRef, useEffect, useState } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Seo from "src/components/global/Seo.js";

import Nav from "src/components/global/Nav.js";
import main from "src/assets/styles/main.js";

import MainWrapper from "src/components/global/MainWrapper.js";
import Line from "src/components/global/Line.js";
import Body from "src/components/global/Typo/Body.js";
import Footer from "src/components/global/Footer.js";
import distances from "src/assets/styles/distances.js";
import mediaQuery from "src/assets/styles/mediaQuery.js";

import Img from "gatsby-image";
import Arrow from "src/assets/svg/arrow-drag.inline.svg";

import ContactForm from "src/components/contactForm/ContactFormOpen.js";

const Wrapper = styled.div`
  margin-bottom: 50rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-bottom: ${distances.pageMarginSmall}rem;
  }
`;
const AboutImg = styled(Img)`
  width: 100%;
  margin-top: 50rem;
  overflow: hidden;
  border-radius: ${main.radius}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    /* order: 3; */
    display: none;
  }
`;

const Form = ({ data, pageContext }) => {
  // useInterval(() => {
  //   if (isBrowser) {
  //     if (document.body.getBoundingClientRect().top > scrollTop) {
  //       // setRDist(scrollTop * speedR);
  //       setLDist(scrollTop * speedL);
  //       setScrollTop(document.body.getBoundingClientRect().top);
  //       // console.log(scrollTop * speedL);
  //     } else {
  //       // setRDist(scrollTop * speedR);
  //       setLDist(scrollTop * speedL);
  //       // console.log(scrollTop * speedL);
  //       setScrollTop(document.body.getBoundingClientRect().top);
  //     }
  //   }
  // }, 1);

  // function useInterval(callback, delay) {
  //   const savedCallback = useRef();

  //   // Remember the latest callback.
  //   useEffect(() => {
  //     savedCallback.current = callback;
  //   }, [callback]);

  //   // Set up the interval.
  //   useEffect(() => {
  //     function tick() {
  //       savedCallback.current();
  //     }
  //     if (delay !== null) {
  //       let id = setInterval(tick, delay);
  //       return () => clearInterval(id);
  //     }
  //   }, [delay]);
  // }
  const [openVid, setOpenVid] = useState(false);

  return (
    <Wrapper>
      <ContactForm lang={pageContext.locale} />
      <Seo
        title={
          pageContext.locale === "pl" ? "Zaplanuj projekt" : "Plan the project"
        }
      />
      <Nav
        hideForm
        contactLink={data.strapiContactLink}
        newsLinkPage={data.strapiPageNews.Page_name}
        lang={pageContext.locale}
        langPageName={pageContext.langPageName}
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        shopPage={data.strapiPageShop.Page_name}
      />

      <Footer
        aboutPage={data.strapiPageAbout.Page_name}
        worksPage={data.strapiPageWork.Page_name}
        newsPage={data.strapiPageNews.Page_name}
        contactPage={data.strapiPageContact.Page_name}
        texts={data.strapiFooter}
        contactLink={data.strapiContactLink}
        lang={pageContext.locale}
      />
    </Wrapper>
  );
};

export default Form;

export const IndexQuery = graphql`
  query Form($locale: String!) {
    strapiPageAbout(locale: { eq: $locale }) {
      Page_name
    }

    strapiFooter(locale: { eq: $locale }) {
      Mail_text
      Tel_text
      Top_text
    }

    strapiContactLink(locale: { eq: $locale }) {
      Main_mail
      Main_tel
      Social_link {
        Link
        Name
      }
      Map_link
      City
      Zip_code
      Street
      Map_text
    }

    strapiPageNews(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageWork(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageContact(locale: { eq: $locale }) {
      Page_name
    }
    strapiPageShop(locale: { eq: $locale }) {
      Page_name
    }
  }
`;
